import React from 'react'
import { TextField } from '@aws-amplify/ui-react';
import logo from './logo.svg';
import './App.css';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { SearchProvider, Results, SearchBox } from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { Storage } from "@aws-amplify/storage"
import Search from './Components/Search/Search.js'

// Material-UI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import awsExports from './aws-exports';

import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import Header from './Components/Header/Header.js'
import SideBar from './Components/SideBar/SideBar.js'
import Dashboard from './Layouts/Dashboard.js'
///import PodSearch from './Components/PodSearch/PodSearch.js'


Amplify.configure(awsExports);









// Amplify.configure({
//   Auth: {
//       identityPoolId: 'eu-west-1:a86f6585-d334-489c-b041-796663e970b4', //REQUIRED - Amazon Cognito Identity Pool ID
//       region: 'eu-west-1', // REQUIRED - Amazon Cognito Region
//       userPoolId: 'eu-west-1_cBxqmlKir', //OPTIONAL - Amazon Cognito User Pool ID
//       userPoolWebClientId: '40blt1v5h8sohjm13t3up8o2b8', //OPTIONAL - Amazon Cognito Web Client ID
//   },
//   Storage: {
//       AWSS3: {
//           bucket: 'ezk-manifest-documents', //REQUIRED -  Amazon S3 bucket name
//           region: 'eu-west-1', //OPTIONAL -  Amazon service region
//       }
//   }
// });
const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function App() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  

    //  const downloadFile = async file => {
    //   //const s3 = await Storage.get('production/EZK-739');
    //   console.log("Printing File:" + " " + file)
    //   const signedURL = await Storage.get(file,{expires: 60}); // get key from Storage.list
    //   return signedURL
    // }
    
    // downloadFile('production/EZK-739').then((data) => {
    //   console.log("S3 URL:" + " " + data)
    // }).catch((err) => {
    //   console.log(err)
    // }) 
    const drawerWidth = 240;

    return (
      <Authenticator
        hideSignUp={true}
      >
      {({ signOut, user }) => (
        <main>
          <Header signOut={signOut}></Header>
          
          <Dashboard>
          <Search></Search>
          </Dashboard>
          
        </main>
      )}
    </Authenticator>
    );
 
}

export default App;
