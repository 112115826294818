import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      TEST
    </Box>
  );


const PodCard = ({comp_code,s3_file_name,s3_url,other,downloadHandler}) => {
        const [useDownloadPOD, setDownloadPOD] = useState(true);
        const [useS3_Url,setS3Url] = useState('');

         

        const fetchPodHandler = (e) => {
            
            console.log("Event Target name from POD Cart")
            console.log(e.target.id)
            // downloadHandler(e).then(data => {
            //     console.log("Data recieved from Download Handler")
            //     console.log(data)
            // }).catch((err) => {
            //     console.log(err)
            // })    
            const dh = downloadHandler(e)
            dh.then(function(result){
              console.log(result)
              setDownloadPOD(false)
              setS3Url(result)
            })
        }

        const downloadPOD = () => {
            console.log("Printing S3 URL to be downloaded by browser")
            console.log(useS3_Url)
        }

       
        
    return(
        <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {comp_code}
          </Typography>
          <Typography variant="h5" component="div">
            
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {s3_url}
          </Typography>
        </CardContent>
        <CardActions>
          <Button id={s3_file_name} onClick={fetchPodHandler} size="small">Fetch POD</Button>
        </CardActions>
        <CardActions>
          <Button
            disabled={useDownloadPOD}
            onClick={downloadPOD}
            href={useS3_Url}
            >
            Download POD
          </Button>
        </CardActions>
      </Card>
    );

} 

export default PodCard;